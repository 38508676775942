<template>
  <div>
    <base-info-card
      v-for="feature in features"
      :id="feature.id"
      :key="feature.name"
      :title="feature.name"
    >
      <a
        :text="feature.name"
        class="mb-8 grey--text text-body-1 d-block text-none"
        href="#"
        v-html="`&rsaquo; ${feature.name} ansehen`"
      />
    </base-info-card>
  </div>
</template>

<script>
  export default {
    name: 'SectionFeaturesAlt',

    data: () => ({
      features: [{
                   name: 'Astrologie & Psychologie',
                   id: 'astropsychology',
                 },
                 {
                   name: 'Hypnosetherapie',
                   id: 'hynosetherapie',
                 },
                 {
                   name: 'Kurse & Seminare',
                   id: 'seminare',
                 },
                 {
                   name: 'Preise',
                   id: 'pricing-plan',
                 },
      ],
    }),
  }
</script>
